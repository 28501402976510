import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  PlatformTab,
  PropList,
  PropListItem,
  TestIdPartial,
  Section,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/rn-segmentedbar.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        disableCodeEditing
        code={snippet}
        platform="react-native"
        gitHubLink="modules/visualizations/segmented-bar"
        layout="rn"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="items" types={['VisualizationData[]']} isRequired>
            <Text>Data used to populate bar segments.</Text>
          </PropListItem>

          <PropListItem name="selectedLabels" types={['string[]']}>
            <Text>
              Array of labels corresponding to the currently selected items. The
              selected labels should match labels provided in the items prop.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Events">
          <PropListItem name="onToggleItem" types={['(label: string) => void']}>
            <Text>
              Responds to bar segment press. The provided label string matches
              the label of the segmented bard item as provided in the items
              prop.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <TestIdPartial componentName="segmented bar" />
        </PropList>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React Native"
        componentName="Modules / Segmented Bar"
      />
    </PlatformTab>
  );
};

export default WebTab;
