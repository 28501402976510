import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
  ShowCountPartial,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const SegmentedBar = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.SegmentedBar;
    return <Component {...props} />;
  },
});

const DataCard = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.DataCard;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/segmented-bar.example');
const stateSnippet = require('raw-loader!../../../../data/snippets/segmented-bar-state.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        id="snippet"
        scope={{ React: React, SegmentedBar: SegmentedBar, DataCard: DataCard }}
        code={snippet}
        platform="react"
        gitHubLink="modules/visualizations/segmented-bar"
      />

      <Section title="Props">
        <PropList header="Visual">
          <ClassnamePartial componentName="segmented bar" />

          <EnvironmentPartial />

          <PropListItem name="items" types={['VisualizationData[]']} isRequired>
            <Text>Data used to populate segmented bar.</Text>
          </PropListItem>

          <PropListItem name="selectedLabels" types={['string[]']}>
            <Text>
              Array of labels corresponding to the currently selected items. The
              selected labels should match labels provided in the items prop.
            </Text>
          </PropListItem>

          <ShowCountPartial />
        </PropList>

        <PropList header="Events">
          <PropListItem name="onToggleItem" types={['(label: string) => void']}>
            <Text>
              Responds to bar segment press. The provided label string matches
              the label of the toggled item as provided in the items prop.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <QaIdPartial componentName="segmented bar" />
        </PropList>
      </Section>

      <Section title="Managing State">
        <Text className="uni-margin--half--bottom">
          To make managing the state of the selected bar segments easier, we
          created <code>useToggleArray()</code>, which is a custom React hook
          that accepts an array of labels representing the initally selected
          items, and returns an array with two values:
        </Text>
        <List type="unordered" className="uni-margin--one--bottom">
          <li>
            <code>selectedLabels</code>: An array of currently selected labels
            to pass through the <code>selectedLabels</code> prop.
          </li>
          <li>
            <code>handleToggleItem</code>: A callback function to pass through{' '}
            <code>onToggleItem</code> to handle toggling the selected state of
            each item.
          </li>
        </List>
        <CodeSnippet
          id="state-snippet"
          code={stateSnippet}
          platform="react"
          disableCodeEditing
        />
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Modules / Segmented Bar"
      />
    </PlatformTab>
  );
};

export default WebTab;
